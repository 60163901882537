import React from 'react';
import $ from 'jquery';
import {Summoner} from '../../LoL/types';
import Database from '../../LoL/database';
import ChestChecker from './ChestChecker';
import RiotAPI from '../../LoL/RiotAPI';

const database = new Database();
const api = new RiotAPI();

function LoL() {
    const [summoner, setSummoner] = React.useState<Summoner>();
    const names = ["Phiphi", "Juju", "Lulu", "Kaka", "Fafa"];
    const puuids = [
        "CAeeaTMyEqCc9LsIabZRZ3tMi32syYxThLK-3kkCsQds8c1btAffesHTXWRb1ATaKSrhEoR-O3Fa7Q",
        "nO7hWTSmGQfYxyv8w9sllOkJqIfrX-w1lkGYFpjaWBLeT2uQAlkYaOkvqbipylECtts1nDtic9Dk-A",
        "5sW8b6reEFQ_rFtWF-fGZBNP-OIuATs9kFrHPpHBMZHwOQkcZn1eDexlRDLsL40AuIUKDQxJGV20Kg",
        "62wB4RmalK1d9BqtLIGVnPylyKxsf_teov36UhKoFTTHQkyInNcaVfN0Fsiy6UbiVwYBkrPqIMhXbQ",
        "ZDORPB7dTVSdI1YPPL1qt24yAKaZtsFx3DhpdPrYyVFVnviEN35VYqZZqk5o7DoGL5QJ5nit7uqing",
    ];

    const handleRadio = (event: any) => {
        database.getSummonerByPuuid(api, puuids[event.target.value]).then(
            (data) => {if (data.puuid) setSummoner(data);}
        );
    }

    React.useEffect(() => {
        $('#refresh').off('click').on('click', () => {
            database.refreshSummoner(api, summoner.puuid).then((data) => {if (data) setSummoner(data);});
        });
    });

    React.useEffect(() => {
        database.getSummonerByPuuid(
            api, 'CAeeaTMyEqCc9LsIabZRZ3tMi32syYxThLK-3kkCsQds8c1btAffesHTXWRb1ATaKSrhEoR-O3Fa7Q'
        ).then((data) => {if (data) setSummoner(data);});
    }, []);

    return (
        <>
            <div className="container-fluid d-flex flex-column align-items-center m-3">
                <div className="btn-group" role="group">
                    {names.map((name, index) => {
                        return [
                            <input key={"btn" + index} type="radio" className="btn-check" id={"btn" + index}
                                   value={index} checked={summoner?.puuid === puuids[index]}
                                   onChange={handleRadio}/>,
                            <label key={"lbl" + index} className="btn btn-outline-primary"
                                   htmlFor={"btn" + index}>{name}</label>
                        ];
                    })}
                </div>
                <span className="m-1">{summoner?.name} &bull; lvl {summoner?.summonerLevel}</span>
                <button className="btn btn-primary" id="refresh">Refresh</button>
            </div>
            <ChestChecker api={api} summoner={summoner}/>
        </>
    );
}

export default LoL;

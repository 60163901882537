import React from 'react';
import {Link} from 'react-router-dom';

function Header() {
    return (
        <nav className="navbar sticky-top bg-primary">
            <div className="container justify-content-center fs-4">
                <Link className="nav-link" to="/">About Me</Link>
                <span className="px-5">&bull;</span>
                <Link className="nav-link" to="/tax-estimator">Tax Estimator</Link>
                <span className="px-5">&bull;</span>
                <Link className="nav-link" to="/lol">LoL</Link>
            </div>
        </nav>
    );
}

export default Header;

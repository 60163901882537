import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './darkly_theme.scss';
import TaxEstimator from './Components/TaxEstimator/TaxEstimator';
import Header from './Components/Header';
import LoL from './Components/LoL/LoL';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement as HTMLDivElement);
root.render(
    <BrowserRouter>
        <Header/>
        <Routes>
            <Route path="/tax-estimator" element={<TaxEstimator/>}/>
            <Route path="/lol" element={<LoL/>}/>
        </Routes>
    </BrowserRouter>
);

type Data = {
    Copyright: number,
    Fees: number,
    Fees_period: string,
    Holidays: number,
    Rate: number,
    Work: number,
    Work_period: string,
    Work_rate: string,
}

function convertToYearly(holidays: number, period: string, rate: string, work: number): number {
    let res: number;
    if (period === "h") {
        if (rate === "d") {
            res = work;
        } else if (rate === "w") {
            res = work / 5;
        } else if (rate === "m") {
            res = work / 21.74107;
        } else if (rate === "y") {
            res = work / 260.8929;
        }
        return res * (260.8929 - holidays);
    } else {
        if (rate === "w") {
            res = work * 52.17857;
        } else if (rate === "m") {
            res = work * 12;
        } else if (rate === "y") {
            res = work;
        }
        return res - holidays
    }
}

function multArrays(array1: number[], array2: number[]): number[] {
    return array1.map((item, index) => item * array2[index]);
}

function round(num: number, dec: number = 0): number {
    if (dec) return +num.toFixed(dec);
    return Math.round(num);
}

function setSlices(gross: number, slices: number[], setter: Function) {
    const temp = [];
    slices.forEach((slice) => {
        temp.push(Math.min(Math.max(gross, 0), slice));
        gross -= slice;
    });
    setter(temp);
}

function sumArray(array: number[]) {
    let temp = 0;
    let i = array.length;
    while (i--) temp += array[i];
    return temp
}

function toPercentOrEuro(num: number): string {
    if (!num) return "-";
    if (num < 1) return round(num * 100, 2).toString() + "%";
    return num.toString() + "€";
}

export {
    Data,
    convertToYearly,
    multArrays,
    round,
    setSlices,
    sumArray,
    toPercentOrEuro,
}

import $ from 'jquery';
import RiotAPI from './RiotAPI';
import {Summoner} from './types';


export default class Database {
    async getSummonerByPuuid(api: RiotAPI, puuid: string): Promise<Summoner> {
        let data: Summoner;
        await $.get('database.php', {query: `SELECT * FROM Summoner WHERE puuid='${puuid}';`})
            .done((response) => {data = response})
            .catch((_, __, e) => {console.log("Database error: " + e)});
        if (data['0'] && data['0'] !== '<') {
            data = data['0'];
        } else {
            data = await this.refreshSummoner(api, puuid);
        }
        return data
    }

    async refreshSummoner(api: RiotAPI, puuid: string) {
        const data: Summoner = await api.getSummonerByPuuid(puuid);
        if (data) {
            const gameName = await api.getSummonerGameName(puuid);
            if (gameName) {data.name = gameName}
            this.putSummoner(data).then();
        }
        return data;
    }

    async putSummoner(summoner: Summoner) {
        const query = 'INSERT INTO Summoner (id, puuid, name, summonerLevel)' +
            `VALUES ('${summoner.id}', '${summoner.puuid}', '${summoner.name}', ` +
            `'${summoner.summonerLevel}') ON DUPLICATE KEY UPDATE name='${summoner.name}', ` +
            `summonerLevel='${summoner.summonerLevel}';`;
        await $.post('database.php', {query: query})
            .catch((_, __, e) => {console.log("Database error: " + e)});
    }
}

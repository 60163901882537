import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {Summoner} from './types';


export default class RiotAPI {
    private instance: AxiosInstance = axios.create({
        baseURL: 'https://leaguestatschecker.azurewebsites.net/api/',
        timeout: 1000,
    });

    async getChampionsInfo(puuid: string): Promise<any> {
        return await this.instance.get(
            `/leaguestatschecker?type=champions&puuid=${puuid}`
        ).then(function (response) {
            return response.data;
        }).catch(function (error) {
            alert("Problem getting champions information: " + error?.response?.status);
            return null;
        });
    }

    async getSummonerByPuuid(puuid: string): Promise<Summoner> {
        return await this.instance.get(
            `/leaguestatschecker?type=summoner&puuid=${puuid}`
        ).then(function (response) {
            return response.data;
        }).catch(function (error) {
            alert("Problem getting summoner by puuid: " + error?.response?.status);
            return null;
        });
    }

    async getSummonerGameName(puuid: string): Promise<string> {
        return await this.instance.get(
            `/leaguestatschecker?type=name&puuid=${puuid}`
        ).then(function (response: AxiosResponse<any, { data: { gameName: string } }>) {
            return response.data.gameName;
        }).catch(function (error) {
            alert("Problem getting account game name: " + error?.response?.status);
            return null;
        });
    }
}

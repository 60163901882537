import React from 'react';
import './Error.css';

function Error({type}) {
    return (
        <>
            {(() => {
                switch (type) {
                    case 'required':
                        return (
                            <p className="error">This field is required.</p>
                        )
                    case 'min':
                        return (
                            <p className="error">This field has to be positive.</p>
                        )
                    case 'validate':
                        return (
                            <p className="error">The two periods have to be different.</p>
                        )
                    default:
                        return;
                }
            })()}
        </>
    )
}

export default Error;

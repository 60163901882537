import React, {useState} from 'react';
import './table_head.scss';

const TableHead = ({columns, handleSorting}) => {
    const [order, setOrder] = useState(1);

    const handleSortingChange = (accessor: string) => {
        let sortOrder: number = order === 1 ? -1 : 1;
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };

    return (
        <thead>
        <tr>
            {columns.map(({label, accessor}) => {
                return (
                    <th key={accessor} onClick={() => handleSortingChange(accessor)}>{label}</th>
                );
            })}
        </tr>
        </thead>
    );
};

export default TableHead;

import React, {useEffect, useState} from 'react';
import {multArrays, round, setSlices, sumArray, toPercentOrEuro} from '../../utils';
import {TaxNode} from './types';
import {CompactTable} from '@table-library/react-table-library/compact';

const COLUMNS = [
    {label: "Taxes", renderCell: (item: TaxNode) => item.name},
    {label: "Slice 1", renderCell: (item: TaxNode) => toPercentOrEuro(item.slice1)},
    {label: "Slice 2", renderCell: (item: TaxNode) => toPercentOrEuro(item.slice2)},
    {label: "Slice 3", renderCell: (item: TaxNode) => toPercentOrEuro(item.slice3)},
    {label: "Slice 4", renderCell: (item: TaxNode) => item.slice4 === -1 ? "\u221E" : toPercentOrEuro(item.slice4)},
    {label: "Total", renderCell: (item: TaxNode) => item.total === -1 ? "\u221E" : toPercentOrEuro(item.total)},
];

const rates = [0.25, 0.4, 0.45, 0.5];
const slices = [13870, 10610, 17890, Math.pow(10, 8)];

function Taxes({taxable, t, setT}) {
    const [data, setData] = useState<TaxNode[]>([]);
    const [values, setValues] = useState([0, 0, 0, 0])

    useEffect(() => setSlices(taxable, slices, setValues), [taxable])

    useEffect(() => setT(sumArray(multArrays(values, rates))), [setT, values])

    useEffect(() => {
        const temp: TaxNode[] = [];
        temp.push({
            id: "0", name: "Maximum", slice1: slices[0], slice2: slices[1], slice3: slices[2], slice4: -1, total: -1,
        });
        temp.push({
            id: "1", name: "Taxable", slice1: round(values[0]), slice2: round(values[1]), slice3: round(values[2]),
            slice4: round(values[3]), total: round(taxable),
        });
        temp.push({
            id: "2", name: "Tax", slice1: round(values[0] * rates[0]), slice2: round(values[1] * rates[1]),
            slice3: round(values[2] * rates[2]), slice4: round(values[3] * rates[3]), total: round(t),
        });
        temp.push({
            id: "3", name: "Rate", slice1: rates[0], slice2: rates[1], slice3: rates[2], slice4: rates[3],
            total: values[0] ? round(t / sumArray(values), 4) : 0,
        });
        setData(temp);
    }, [t, taxable, values])

    return (
        <CompactTable data={{nodes: data}} columns={COLUMNS}/>
    );
}

export default Taxes;

import React, {useEffect, useState} from 'react';
import {CompactTable} from '@table-library/react-table-library/compact';
import {convertToYearly, Data, round} from '../../utils';
import Copyrights from './Copyrights';
import Social from './Social';
import Taxes from './Taxes';
import {ResultNode} from './types';

const COLUMNS = [
    {label: "", renderCell: (item: ResultNode) => item.name},
    {label: "Gross", renderCell: (item: ResultNode) => item.gross.toString() + "€"},
    {label: "After Fees", renderCell: (item: ResultNode) => item.taxable.toString() + "€"},
    {label: "After Copyrights", renderCell: (item: ResultNode) => item.copyrighted.toString() + "€"},
    {label: "After Social Charges", renderCell: (item: ResultNode) => item.charged.toString() + "€"},
    {label: "After Taxes (Net/Year)", renderCell: (item: ResultNode) => item.net.toString() + "€"},
    {label: "After Taxes (Net/Month)", renderCell: (item: ResultNode) => round(item.net / 12).toString() + "€"},
];

function Results(props: { data: Data; }) {
    const data: Data = props.data;
    const [gross, setGross] = useState(0);
    const [fees, setFees] = useState(0);
    const [copyrighted, setCopyrighted] = useState(0);
    const [c, setC] = useState(0);
    const [s, setS] = useState(0);
    const [t, setT] = useState(0);

    useEffect(() => {
        if (data) {
            setGross(convertToYearly(data.Holidays, data.Work_period, data.Work_rate, data.Work) * data.Rate);
            setFees(data.Fees_period === "m" ? data.Fees * 12 : data.Fees);
            setCopyrighted((gross - fees) * data.Copyright / 100);
        }
    }, [data, fees, gross])

    return (
        <>
            <CompactTable data={{
                nodes: [{
                    id: "0",
                    name: "Revenue",
                    gross: round(gross),
                    taxable: round(gross - fees),
                    copyrighted: round(gross - fees - c),
                    charged: round(gross - fees - c - s),
                    net: round(gross - fees - c - s - t),
                }]
            }} columns={COLUMNS}/>
            <Copyrights taxable={copyrighted} c={c} setC={setC}/>
            <Social taxable={gross - fees - copyrighted} s={s} setS={setS}/>
            <Taxes taxable={gross - fees - copyrighted - s} t={t} setT={setT}/>
        </>
    );
}

export default Results;

import React from 'react';

const TableBody = ({sortedData, columns}) => {
    return (
        <tbody>
        {sortedData.map((data: any) => {
            return (
                <tr key={data.id}>
                    {columns.map(({accessor}) => {
                        let tData = data[accessor] !== null ? data[accessor] : "——";
                        if (typeof data[accessor] === 'boolean') {
                            tData = data[accessor] ? "\u2714" : "\u274C";
                        }
                        return <td key={accessor}>{tData}</td>;
                    })}
                </tr>
            );
        })}
        </tbody>
    );
};

export default TableBody;

import React, {useState} from 'react';
import Form from './Form';
import Results from './Results';
import {Data} from '../../utils';

function TaxEstimator() {
    const [data, setData] = useState<Data>();
    return (
        <>
            <Form setData={setData}/>
            <Results data={data}/>
        </>
    );
}

export default TaxEstimator;

import React from 'react';
import {useForm} from "react-hook-form";
import './Form.css';
import Error from './Error';

function Form({setData}) {
    const {register, formState: {errors}, getValues, handleSubmit} = useForm();

    const onSubmit = (data: any) => {
        setData(data);
    }

    return (
        <form className="align-center" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <label>Work time</label>
                <input {...register("Work", {required: true, min: 0})} type="number"/>
                <select {...register("Work_period")}>
                    <option value="h">Hours</option>
                    <option value="d">Days</option>
                </select>
                <span>per</span>
                <select {...register("Work_rate", {
                    validate: () => (getValues("Work_period") !== getValues("Work_rate"))
                })}>
                    <option value="d">Day</option>
                    <option value="w">Week</option>
                    <option value="m">Month</option>
                    <option value="y">Year</option>
                </select>
                <Error type={errors.Work?.type}/>
                <Error type={errors.Work_rate?.type}/>
            </div>
            <div>
                <label>Rate</label>
                <input {...register("Rate", {required: true, min: 0})} type="number"/>
                <Error type={errors.Rate?.type}/>
            </div>
            <div>
                <label>Yearly Holidays</label>
                <input {...register("Holidays", {required: true, min: 0})} type="number"/>
                <Error type={errors.Holidays?.type}/>
            </div>
            <div>
                <label>Fees</label>
                <input {...register("Fees", {required: true, min: 0})} type="number"/>
                <select {...register("Fees_period")}>
                    <option value="m">Monthly</option>
                    <option value="y">Yearly</option>
                </select>
                <Error type={errors.Fees?.type}/>
            </div>
            <div>
                <label>Copyright %</label>
                <input {...register("Copyright", {required: true, min: 0, max: 100})} type="number"/>
                <Error type={errors.Copyright?.type}/>
            </div>
            <input type="submit"/>
        </form>
    )
}

export default Form;

import React, {useEffect, useState} from 'react';
import {CompactTable} from '@table-library/react-table-library/compact';
import {multArrays, round, setSlices, sumArray, toPercentOrEuro} from '../../utils';
import {CopyrightNode} from './types';

const COLUMNS = [
    {label: "Copyrights", renderCell: (item: CopyrightNode) => item.name},
    {label: "Slice 1", renderCell: (item: CopyrightNode) => toPercentOrEuro(item.slice1)},
    {label: "Slice 2", renderCell: (item: CopyrightNode) => toPercentOrEuro(item.slice2)},
    {label: "Slice 3", renderCell: (item: CopyrightNode) => toPercentOrEuro(item.slice3)},
    {label: "Total", renderCell: (item: CopyrightNode) => toPercentOrEuro(item.total)},
];

const rates = [0.075, 0.1125, 0.15];
const slices = [15050, 15060, 26340];

function Copyrights({c, taxable, setC}) {
    const [data, setData] = useState<CopyrightNode[]>([]);
    const [values, setValues] = useState([0, 0, 0]);

    useEffect(() => setSlices(taxable, slices, setValues), [taxable])

    useEffect(() => setC(sumArray(multArrays(values, rates))), [values, setC])

    useEffect(() => {
        const temp: CopyrightNode[] = [];
        temp.push({
            id: "0", name: "Maximum", slice1: slices[0], slice2: slices[1], slice3: slices[2],
            total: sumArray(slices),
        });
        temp.push({
            id: "1", name: "Taxable", slice1: round(values[0]), slice2: round(values[1]),
            slice3: round(values[2]), total: round(Math.min(taxable, sumArray(slices))),
        });
        temp.push({
            id: "2", name: "Tax", slice1: round(values[0] * rates[0]), slice2: round(values[1] * rates[1]),
            slice3: round(values[2] * rates[2]), total: round(c),
        });
        temp.push({
            id: "3", name: "Rate", slice1: rates[0], slice2: rates[1], slice3: rates[2],
            total: values[0] ? round(c / sumArray(values), 4) : 0,
        });
        setData(temp);
    }, [c, taxable, values])

    return (
        <CompactTable data={{nodes: data}} columns={COLUMNS}/>
    );
}

export default Copyrights;

import TableHead from './TableHead';
import TableBody from './TableBody';
import React, {useEffect, useState} from 'react';

const SortTable = ({tableData, columns}) => {
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {setSortedData(tableData)}, [tableData]);

    const handleSorting = (accessor: string, order: number) => {
        let sorted: any[];
        if (accessor && accessor !== 'chestGranted') {
            sorted = [...tableData].sort((a, b) => {
                if (a[accessor] === null && b[accessor] === null) return 0;
                if (a[accessor] === null) return 1;
                if (b[accessor] === null) return -1;
                return a[accessor].toString().localeCompare(b[accessor].toString(), 'en', {numeric: true}) * order;

            });
        } else if (accessor) {
            sorted = [...tableData].filter((item) => order === 1 ? item.chestGranted : !item.chestGranted);
        }
        if (sorted) setSortedData(sorted);
    };

    return (
        <table className="table table-dark">
            <TableHead columns={columns} handleSorting={handleSorting}></TableHead>
            <TableBody columns={columns} sortedData={sortedData}></TableBody>
        </table>
    );
};

export default SortTable;

import React from 'react';
import $ from 'jquery';
import {Champion, ChampionNode, CheckerProps} from '../../LoL/types';
import SortTable from '../SortTable';

const COLUMNS = [
    {label: "Champion", accessor: 'name'},
    {label: "Level", accessor: 'championLevel'},
    {label: "Mastery", accessor: 'championPoints'},
    {label: "Chest", accessor: 'chestGranted'},
];

function ChestChecker(props: CheckerProps) {
    const [champions, setChampions] = React.useState<any>({});
    const [data, setData] = React.useState<ChampionNode[]>([]);

    // Gets the latest LoL version, then the list of champions for that version.
    const getChampions = () => {
        $.getJSON('https://ddragon.leagueoflegends.com/api/versions.json', function (data) {
            $.getJSON(`https://ddragon.leagueoflegends.com/cdn/${data[0]}/data/en_US/champion.json`,
                function (data: { data: { key: any } }) {
                    const temp = {};
                    for (const [key, value] of Object.entries(data.data)) {
                        temp[value.key] = key;
                    }
                    setChampions(temp);
                });
        });
    }

    React.useEffect(() => getChampions(), []);

    React.useEffect(() => {
        if (props.summoner && props.summoner.puuid) {
            props.api.getChampionsInfo(props.summoner.puuid).then((data: Champion[]) => {
                if (Object.keys(champions).length) {
                    const temp: ChampionNode[] = [];
                    for (const champ of data) {
                        temp.push({
                            championLevel: champ.championLevel,
                            championPoints: champ.championPoints,
                            chestGranted: champ.chestGranted,
                            id: champ.championId.toString(),
                            name: champions[champ.championId],
                        });
                    }
                    setData(temp);
                }
            });
        }
    }, [champions, props.api, props.summoner]);

    return (
        <>
            <h3>
                Chest Checker
            </h3>
            <SortTable tableData={data} columns={COLUMNS}/>
        </>
    );
}

export default ChestChecker;

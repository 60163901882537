import React, {useEffect, useState} from 'react';
import {multArrays, round, setSlices, sumArray, toPercentOrEuro} from '../../utils';
import {SocialNode} from './types';
import {CompactTable} from '@table-library/react-table-library/compact';

const COLUMNS = [
    {label: "Social Charges", renderCell: (item: SocialNode) => item.name},
    {label: "Slice 1", renderCell: (item: SocialNode) => toPercentOrEuro(item.slice1)},
    {label: "Slice 2", renderCell: (item: SocialNode) => toPercentOrEuro(item.slice2)},
    {label: "Total", renderCell: (item: SocialNode) => toPercentOrEuro(item.total)},
];

const rates = [0.205, 0.1416, 1.0405];
const slices = [58513.59, 27716.93];

function Copyrights({taxable, s, setS}) {
    const [data, setData] = useState<SocialNode[]>([]);
    const [values, setValues] = useState([0, 0]);

    useEffect(() => setSlices(taxable, slices, setValues), [taxable])

    useEffect(() => setS(sumArray(multArrays(values, rates)) * rates[2]), [setS, values])

    useEffect(() => {
        const temp: SocialNode[] = [];
        temp.push({id: "0", name: "Maximum", slice1: slices[0], slice2: slices[1], total: round(sumArray(slices), 2)});
        temp.push({
            id: "1", name: "Taxable", slice1: round(values[0], 2), slice2: round(values[1], 2),
            total: round(Math.min(taxable, sumArray(slices)), 2),
        });
        temp.push({
            id: "2", name: "Tax", slice1: round(values[0] * rates[0], 2), slice2: round(values[1] * rates[1], 2),
            total: round(s, 2),
        });
        temp.push({
            id: "3", name: "Rate", slice1: rates[0], slice2: rates[1],
            total: values[0] ? round(s / sumArray(values), 4) : 0,
        });
        setData(temp);
    }, [s, taxable, values])

    return (
        <CompactTable data={{nodes: data}} columns={COLUMNS}/>
    );
}

export default Copyrights;
